import Item from '@/domain/Item';
import CreateTrackedItemDTO from '@/dtos/CreateNewTrackedItemDTO';
import { normalizeDate } from '@/functions/date';

export default class TrackedItem {
    public id!: number;

    public item!: Item;

    public itemId!: number;

    public barcode!: string;

    public deleted!: boolean;

    public productionPlant?: string;

    public productionDate? : Date;

    public virtualWarehouse?: string;

    public additionalReference?: string;

    public manufacturingOrderNumber?: string;

    public orderNumberLine?: string;

    public partQuantity?: number;

    public uom?: string;

    constructor(init?: TrackedItem) {
        if (init) {
            this.id = init.id;
            this.item = new Item(init.item);
            this.itemId = init.item.id;
            this.barcode = init.barcode;
            this.deleted = init.deleted;
            this.productionPlant = init.productionPlant;
            this.productionDate = normalizeDate(init.productionDate);
            this.virtualWarehouse = init.virtualWarehouse;
            this.additionalReference = init.additionalReference;
            this.manufacturingOrderNumber = init.manufacturingOrderNumber;
            this.orderNumberLine = init.orderNumberLine;
            this.partQuantity = init.partQuantity;
            this.uom = init.uom;
        }
    }

    toCreateTrackedItemDTO(): CreateTrackedItemDTO {
        return new CreateTrackedItemDTO(this);
    }
}
